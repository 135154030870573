<template>
  <div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        :page-sizes="[10, 50, 100, 200, 500, 1000]"
        :pager-count="count"
        background
        :layout="layout"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name:'Pagination',
  props: ["isSizes", "size", "total", "page" ,"count"],
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    nowpage() {
      return this.currentPage;
    },
    layout() {
      let str = "total, sizes, prev, pager, next, jumper";
      this.isSizes?str="sizes,"+str:"";
      return str;
    }
  },
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("pageChange", val);
      //console.log(`当前页: ${val}`);
      //console.log(val)
    }
  },
  mounted() {
    //this.$emit('pageChange',this.nowpage )
  }
};
</script>

<style scoped lang="less">
.block /deep/{
  /* text-align: center;
    background: #fff;
    margin-top: 10px; */
  /* border: 1px solid #ebeef5 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  .el-pager li {
    margin: 0 5px;
    border-radius: 2px;
  }
}
</style>
